<template>
  <div>
    <div class="isrelative">

      <CreatorFolio
        :title="input.title"
        :about="input.about"
        :image="input.image"
        :video="input.video"
        :video-thumbnail="input.videoThumbnail"
        :effect="input.effect"
        :primary-color="input.primaryColor"
        :secondary-color="input.secondaryColor"
        :n="input.n"
      >
        <template #edit>
          <div
            v-if="isOwner === true"
            class="creatorfolio-edit-container"
          >
            <div class="creatorfolio-edit-box">
              <div class="swatches-item">
                <div
                  class="edit-creator-field"
                >
                  Text
                </div>
                <div
                  class="edit-icon"
                  @click="setTextModal = true"
                />
              </div>
              <form class="swatches-item">
                <div
                  class="edit-creator-field"
                >
                  Set Image
                </div>
                <label
                  class="creator-file-upload"
                  for="creator-file-upload"
                />
                <input
                  id="creator-file-upload"
                  type="file"
                  accept="image/*,video/*"
                  @change="setCreatorBackdrop"
                >
              </form>
              <div class="flex-col swatches-edit">
                <div class="swatches-item">
                  <div class="edit-creator-color">
                    Primary
                  </div>
                  <VSwatches
                    v-model="input.primaryColor"
                    class="swatch-style"
                    label="Background Color"
                    show-border="true"
                    :swatches="swatches"
                    row-length="6"
                    swatch-size="24"
                    shapes="squares"
                    popover-x="left"
                    show-fallback
                    fallback-input-type="color"
                  >
                    <template #trigger class="swatch-style">
                      <div :style="'background-color: '
                        + input.primaryColor
                        + '; width:20px; height: 20px; border: 1px solid; border-radius: 3px; margin: 5px; cursor: pointer;'"
                      >
                        <input :value="input.secondaryColor" class="swatch-style" readonly />
                      </div>
                    </template>
                  </VSwatches>
                </div>
                <div class="swatches-item">
                  <div class="edit-creator-color">
                    Secondary
                  </div>
                  <VSwatches
                    v-model="input.secondaryColor"
                    class="swatch-style"
                    label="Background Color"
                    show-border="true"
                    :swatches="swatches"
                    row-length="6"
                    swatch-size="24"
                    shapes="squares"
                    popover-x="left"
                    show-fallback
                    fallback-input-type="color"
                  >
                    <template #trigger class="swatch-style">
                      <div :style="'background-color: '
                        + input.secondaryColor
                        + '; width:20px; height: 20px; border: 1px solid; border-radius: 3px; margin: 5px; cursor: pointer;'"
                      >
                        <input :value="input.secondaryColor" class="swatch-style" readonly />
                      </div>
                    </template>
                  </VSwatches>
                </div>
              </div>
              <div
                class="save-creator"
                @click="saveCreatorFolio"
              >
                {{ localize('TEXT_SAVE') }}
              </div>
            </div>
          </div>
        </template>
      </CreatorFolio>

      <div class="creatorfolio-add-container">
        <div class="creatorfolio-add-container-left">
          <div
            v-if="isOwner === true"
            class="flex-row"
          >
            <div
              class="generic-button"
            >
              <div
                class="creator-create-text"
                @click="createTokenModal = true"
              >
                + {{ localize('TEXT_CREATE_NFT') }}
              </div>
            </div>
            <div
              v-if="isEcommerceEnabled() == true"
              class="generic-button"
            >
              <div
                class="creator-create-text"
                @click="gotoTab('create-product')"
              >
                + {{ localize('TEXT_CREATE_PRODUCT') }}
              </div>
            </div>
<!--
            <div
              class="generic-button"
              @click="createPostModal = true"
            >
              <div class="creator-create-text">
                + {{ localize('TEXT_POST_UPDATE') }}
              </div>
            </div>
-->
            <div
              v-if="isOwner === true"
              class="generic-button"
              @click="openSmModal = true"
            >
              <span class="plus-icon">
                +
              </span>
              <div class="creator-create-text">
                {{ localize('TEXT_EDIT_SOCIAL') }}
              </div>
            </div>
            <div
              v-if="isOwner === true"
              class="generic-button"
              @click="addSegment"
            >
              <span class="plus-icon">
                +
              </span>
              <div class="creator-create-text">
                {{ localize('TEXT_ADD_SECTION') }}
              </div>
            </div>
            <div
              v-if="isOwner === true"
              class="generic-button"
              @click="showDoodleModal = true"
            >
              <span class="plus-icon">
                +
              </span>
              <div class="creator-create-text">
                {{ localize('TEXT_EDIT_DOODLE') }}
              </div>
            </div>
          </div>
        </div>
        <div class="creatorfolio-add-container-right">
          <div
            v-if="sm.website"
            class="sm-website"
            @click="gotoUrl(sm.website)"
          />
          <div
            v-if="sm.instagram"
            class="sm-instagram"
            @click="gotoUrl(sm.instagram)"
          />
          <div
            v-if="sm.twitter"
            class="sm-twitter"
            @click="gotoUrl(sm.twitter)"
          />
          <div
            v-if="sm.twitch"
            class="sm-twitch"
            @click="gotoUrl(sm.twitch)"
          />
          <div
            v-if="sm.youtube"
            class="sm-youtube"
            @click="gotoUrl(sm.youtube)"
          />
        </div>
      </div>
    </div>

    <div
      class="content-container-outer"
    >

        <Doodle
          :doodleKey="doodleId"
          :data="doodle"
          class="doodle"
        />

        <div
          class="content-container-inner"
        >

          <div
            v-for="(p,i) in segments"
            class="project-container"
          >
            <div
              class="section-container"
            >

              <div
                class="edit-section-buttons"
                v-if="isOwner === true"
              >

                <div class="flex-col">
                  <div
                    class="edit-custom-field"
                  >
                    Background
                  </div>
                  <div class="flex-row">
                    <form class="swatches-item">
                      <label
                        class="creator-file-upload-dark"
                        :for="'segment-file-upload-' + i"
                      />
                      <input
                        class="creator-file-upload"
                        :id="'segment-file-upload-' + i"
                        type="file"
                        accept="image/*,video/*"
                        @change="setBackdrop($event,i,-1)"
                      >
                    </form>
                    <div
                      @click="setBackgroundColor(i,-1)"
                      class="flex-row"
                    >
                      <VSwatches
                        v-model="segments[i].backgroundColorA"
                        class="swatch-style"
                        label="Background Color A"
                        show-border="true"
                        :swatches="swatches"
                        row-length="6"
                        swatch-size="24"
                        shapes="squares"
                        popover-x="left"
                        show-fallback
                        fallback-input-type="color"
                      >
                        <template #trigger class="swatch-style">
                          <div :style="'background-color: '
                            + segments[i].backgroundColorA
                            + '; width:20px; height: 20px; border: 1px solid; border-radius: 3px; margin: 5px; cursor: pointer;'"
                          >
                            <input :value="segments[i].backgroundColorA" class="swatch-style" readonly />
                          </div>
                        </template>
                      </VSwatches>
                      <VSwatches
                        v-model="segments[i].backgroundColorB"
                        class="swatch-style"
                        label="Background Color B"
                        show-border="true"
                        :swatches="swatches"
                        row-length="6"
                        swatch-size="24"
                        shapes="squares"
                        popover-x="left"
                        show-fallback
                        fallback-input-type="color"
                      >
                        <template #trigger class="swatch-style">
                          <div :style="'background-color: '
                            + segments[i].backgroundColorB
                            + '; width:20px; height: 20px; border: 1px solid; border-radius: 3px; margin: 5px; cursor: pointer;'"
                          >
                            <input :value="segments[i].backgroundColorB" class="swatch-style" readonly />
                          </div>
                        </template>
                      </VSwatches>
                    </div>
                  </div>
                  <div
                    @click="removeBackdrop(i,-1)"
                    class="menu-small-click"
                  >
                  {{ localize('TEXT_RESET') }}
                  </div>
                </div>

                <div class="flex-edit-items">
                  <CDropdown
                    title="Margin"
                    :toggler-text="segments[i].marginValue"
                    class="orientation-dropdown-item"
                  >
                    <CDropdownItem
                      v-for="o in ['none','small','medium','large','extreme']"
                      @click.native="setMargin(i,-1,o)"
                    >
                      {{ o }}
                    </CDropdownItem>
                  </CDropdown>
                </div>

                <div class="flex-edit-items">
                  <CDropdown
                    title="Border"
                    :toggler-text="segments[i].borderValue"
                    class="orientation-dropdown-item"
                  >
                    <CDropdownItem
                      v-for="o in ['none','light','medium','strong','extreme']"
                      @click.native="setBorder(i,-1,o)"
                    >
                      {{ o }}
                    </CDropdownItem>
                  </CDropdown>

                  <div class="flex-edit-items margin-top-thirty">
                    <VSwatches
                      v-model="segments[i].borderColor"
                      class="swatch-style"
                      label="Background Color"
                      show-border="true"
                      :swatches="swatches"
                      row-length="6"
                      swatch-size="24"
                      shapes="squares"
                      popover-x="left"
                      show-fallback
                      fallback-input-type="color"
                    >
                      <template #trigger class="swatch-style">
                        <div :style="'background-color: '
                          + segments[i].borderColor
                          + '; width:20px; height: 20px; border: 1px solid; border-radius: 3px; margin: 5px; cursor: pointer;'"
                        >
                          <input :value="segments[i].borderColor" class="swatch-style" readonly />
                        </div>
                      </template>
                    </VSwatches>
                  </div>
                </div>

                <div class="flex-edit-items">
                  <CDropdown
                    title="Border Style"
                    :toggler-text="segments[i].borderStyleValue"
                    class="orientation-dropdown-item"
                  >
                    <CDropdownItem
                      v-for="o in ['none','round-light','round-medium','round-strong','round-extreme','angular-a','angular-b','angular-c']"
                      @click.native="setBorderStyle(i,-1,o)"
                    >
                      {{ o }}
                    </CDropdownItem>
                  </CDropdown>
                </div>

                <div class="flex-edit-items">
                  <CDropdown
                    title="Box Shadow"
                    :toggler-text="segments[i].boxShadowValue"
                    class="orientation-dropdown-item"
                  >
                    <CDropdownItem
                      v-for="o in ['none','light','medium','strong']"
                      @click.native="setBoxShadow(i,-1,o)"
                    >
                      {{ o }}
                    </CDropdownItem>
                  </CDropdown>
                </div>

                <div class="flex-edit-items">
                  <CDropdown
                    title="Orientation"
                    :toggler-text="segments[i].orientation"
                    class="orientation-dropdown-item"
                  >
                    <CDropdownItem
                      v-for="o in ['row','column']"
                      @click.native="setSegmentOrientation(i,o)"
                    >
                      {{ o }}
                    </CDropdownItem>
                  </CDropdown>

                  <div class="add-buttons-wrapper">

                    <div>
                      <div class="normal-link"
                        @click="addComponent(i, 'component')"
                      >
                          + Component
                      </div>
                    </div>

                    <div>
                      <div class="normal-link"
                        @click="showModal(i, 'launcher')"
                      >
                        + NFT Launcher
                      </div>

                    </div>

                    <div>
                      <div class="normal-link"
                        @click="showModal(i, 'minter')"
                      >
                        + NFT Minter
                      </div>

                    </div>

                  </div>

                </div>

                <div class="flex-column">

                  <div class="flex-row">

                    <div
                      @click="moveSegmentUp(i)"
                      class="menu-small-click"
                    >
                      Up ^
                    </div>

                    <div
                      @click="moveSegmentDown(i)"
                      class="menu-small-click"
                    >
                      Down v
                    </div>

                  </div>

                  <div
                    @click="removeSegment(i)"
                    class="menu-small-click"
                  >
                    Delete
                  </div>

                </div>

              </div>

    <!--
              <Transition
                mode="slide-fade"
              >
                  class="segment-backdrop"
    -->

                <div
                  :id="'segment-backdrop-' + i"
                  :style="'background-color: ' + segments[i].backgroundColor + ';'
                      + 'background: ' + segments[i].background + ';'
                      + 'height: ' + segments[i].height + ';'
                      + 'border: ' + segments[i].border + ';'
                      + 'border-radius: ' + segments[i].borderRadius + ';'
                      + '-webkit-clip-path: ' + segments[i].angularBorderStyle + ';'
                      + 'clip-path: ' + segments[i].angularBorderStyle + ';'
                      + 'margin: ' + segments[i].margin + ';'
                      + 'box-shadow: ' + segments[i].boxShadow + ';'
                      + 'z-index: 0;'
                      + 'position: relative;'"
                >
                  <div
                    v-if="segments[i].image"
                  >
                    <img
                      :id="'segment-image-' + i"
                      :src="segments[i].image"
                      class="segment-image"
                    />
                  </div>
                  <video
                    v-if="segments[i].video"
                    :id="'segment-video-' + i"
                    class="segment-video"
                    :poster=segments[i].poster
                    :key="segments[i].video"
                    loop
                    autoplay
                    muted
                  >
                    <source
                      :src="segments[i].video"
                      type="video/mp4"
                    >
                  </video>

                  <div
                    :class="getOrientation(segments[i].orientation)"
                  >
                    <ProjectComponent
                      v-for="(d,j) in segments[i].components"
                      @setComponentImage="setComponentImage"
                      @setOrientation="setOrientation"
                      @removeComponent="removeComponent"
                      :id="'s-' + i + '-' + j"
                      :data="d"
                      :segmentId="i"
                      :componentId="j"
                      :orientation="d.orientation"
                      :owner="isOwner === true"
                      :style="'margin: ' + segments[i].components[j].margin + ';'">
                      <template #edit>
                        <div
                          v-if="userInfo.user_id == owner"
                        >

                          <div class="flex-col">
                            <div
                              class="edit-custom-field"
                            >
                              Background
                            </div>
                            <div class="flex-row">
                              <form class="swatches-item">
                                <label
                                  class="creator-file-upload-dark"
                                  :for="'segment-' + i + '-file-upload-' + j"
                                />
                                <input
                                  class="creator-file-upload"
                                  :id="'segment-' + i + '-file-upload-' + j"
                                  type="file"
                                  accept="image/*,video/*"
                                  @change="setBackdrop($event,i,j)"
                                >
                              </form>
                              <div
                                @click="setBackgroundColor(i,j)"
                                class="flex-row"
                              >
                                <VSwatches
                                  v-model="segments[i].components[j].backgroundColorA"
                                  class="swatch-style"
                                  label="Background Color A"
                                  show-border="true"
                                  :swatches="swatches"
                                  row-length="6"
                                  swatch-size="24"
                                  shapes="squares"
                                  popover-x="left"
                                  show-fallback
                                  fallback-input-type="color"
                                >
                                  <template #trigger class="swatch-style">
                                    <div :style="'background-color: '
                                      + segments[i].components[j].backgroundColorA
                                      + '; width:20px; height: 20px; border: 1px solid; border-radius: 3px; margin: 5px; cursor: pointer;'"
                                    >
                                      <input :value="segments[i].components[j].backgroundColorA" class="swatch-style" readonly />
                                    </div>
                                  </template>
                                </VSwatches>
                                <VSwatches
                                  v-model="segments[i].components[j].backgroundColorB"
                                  class="swatch-style"
                                  label="Background Color B"
                                  show-border="true"
                                  :swatches="swatches"
                                  row-length="6"
                                  swatch-size="24"
                                  shapes="squares"
                                  popover-x="left"
                                  show-fallback
                                  fallback-input-type="color"
                                >
                                  <template #trigger class="swatch-style">
                                    <div :style="'background-color: '
                                      + segments[i].components[j].backgroundColorB
                                      + '; width:20px; height: 20px; border: 1px solid; border-radius: 3px; margin: 5px; cursor: pointer;'"
                                    >
                                      <input :value="segments[i].components[j].backgroundColorB" class="swatch-style" readonly />
                                    </div>
                                  </template>
                                </VSwatches>
                              </div>
                            </div>
                            <div
                              @click="removeBackdrop(i,j)"
                              class="menu-small-click"
                            >
                              {{ localize('TEXT_RESET') }}
                            </div>
                          </div>
                        </div>

                        <div class="flex-col set-color-container">

                          <div class="item-title margin-single-swatch">
                            Color
                          </div>

                          <div class="flex-edit-items">
                            <VSwatches
                              v-model="segments[i].components[j].color"
                              class="swatch-style"
                              label="Color"
                              show-border="true"
                              :swatches="swatches"
                              row-length="6"
                              swatch-size="24"
                              shapes="squares"
                              popover-x="left"
                              show-fallback
                              fallback-input-type="#000"
                            >
                              <template #trigger class="swatch-style">
                                <div :style="'background-color: '
                                  + segments[i].components[j].color
                                  + '; width:20px; height: 20px; border: 1px solid; border-radius: 3px; margin: 5px; cursor: pointer;'"
                                >
                                  <input :value="segments[i].components[j].color" class="swatch-style" readonly />
                                </div>
                              </template>
                            </VSwatches>
                          </div>

                        </div>

                        <div class="flex-edit-items">
                          <CDropdown
                            title="Margin"
                            :toggler-text="segments[i].components[j].marginValue"
                            class="orientation-dropdown-item"
                          >
                            <CDropdownItem
                              v-for="o in ['none','small','medium','large','extreme']"
                              @click.native="setMargin(i,j,o)"
                            >
                              {{ o }}
                            </CDropdownItem>
                          </CDropdown>
                        </div>

                        <div class="flex-edit-items">
                          <CDropdown
                            title="Border"
                            :toggler-text="segments[i].components[j].borderValue"
                            class="orientation-dropdown-item"
                          >
                            <CDropdownItem
                              v-for="o in ['none','light','medium','strong','extreme']"
                              @click.native="setBorder(i,j,o)"
                            >
                              {{ o }}
                            </CDropdownItem>
                          </CDropdown>

                          <div class="flex-edit-items margin-top-thirty">
                            <VSwatches
                              v-model="segments[i].components[j].borderColor"
                              class="swatch-style"
                              label="Background Color"
                              show-border="true"
                              :swatches="swatches"
                              row-length="6"
                              swatch-size="24"
                              shapes="squares"
                              popover-x="left"
                              show-fallback
                              fallback-input-type="color"
                            >
                              <template #trigger class="swatch-style">
                                <div :style="'background-color: '
                                  + segments[i].components[j].borderColor
                                  + '; width:20px; height: 20px; border: 1px solid; border-radius: 3px; margin: 5px; cursor: pointer;'"
                                >
                                  <input :value="segments[i].components[j].borderColor" class="swatch-style" readonly />
                                </div>
                              </template>
                            </VSwatches>
                          </div>
                        </div>

                        <div class="flex-edit-items">
                          <CDropdown
                            title="Border Style"
                            :toggler-text="segments[i].components[j].borderStyleValue"
                            class="orientation-dropdown-item"
                          >
                            <CDropdownItem
                              v-for="o in ['none','round-light','round-medium','round-strong','round-extreme','angular-a','angular-b','angular-c']"
                              @click.native="setBorderStyle(i,j,o)"
                            >
                              {{ o }}
                            </CDropdownItem>
                          </CDropdown>
                        </div>

                        <div class="flex-edit-items">
                          <CDropdown
                            title="Box Shadow"
                            :toggler-text="segments[i].components[j].boxShadowValue"
                            class="orientation-dropdown-item"
                          >
                            <CDropdownItem
                              v-for="o in ['none','light','medium','strong']"
                              @click.native="setBoxShadow(i,j,o)"
                            >
                              {{ o }}
                            </CDropdownItem>
                          </CDropdown>
                        </div>

                      </template>
                    </ProjectComponent>
                  </div>

                </div>

    <!--
              </Transition>
    -->

            </div>
          </div>
        </div>
    </div>

    <div
      v-for="i in creatorposts"
      :key="i.id"
      :class="creatorpostsClass"
    >
      <div class="creatorposts-wrapper">
        <div>
          <CreatorPost
            :id="i.id"
            :content="i.content"
            :url="i.preview.url"
            :title="i.preview.title"
            :site-name="i.preview.siteName"
            :description="i.preview.description"
            :media-type="i.preview.mediaType"
            :content-type="i.preview.contentType"
            :image="i.preview.image"
            :video="i.preview.video"
            :favicons="i.preview.favicons"
            :owner="owner"
            :created="i.created"
            :edited="i.edited"
            @edit-post="editPost(i)"
          />
        </div>
      </div>
    </div>

    <CollectionPreview
      v-for="a in collections"
      class="mid-section"
      :collection="a"
      @click="gotoCollection(a.nft_address)"
    />

    <CModal
      v-if="showDoodleModal"
      color="#995dd4"
      width="large"
      @close="showDoodleModal = false"
    >
      <template #header>
        <div> Doodle </div>
      </template>
      <template #body>
        <CTextArea
          class="margin-top"
          :value="doodle"
          :title="localize('DATA_CONTENT')"
          :placeholder="localize('TEXT_CONTENT_PLACEHOLDER')"
          @inputValue="setDoodle"
        />
        <div 
          class="generic-button"
          @click="saveDoodle"
        >
          ok
        </div>
      </template>
      <template #footer>
        <div class="hidden" />
      </template>
    </CModal>

    <CModal
      v-if="showMinterModal"
      color="#995dd4"
      width="medium"
      @close="showMinterModal = false"
    >
      <template #header>
        <div> {{ localize('SET_NFT_ADDRESS') }} </div>
      </template>
      <template #body>
        <div>
          <CDropdown
            title="Set NFT Address"
            :toggler-text="segments[selectedSegment].nft_address"
            class="nft-address-dropdown-item"
          >
            <CDropdownItem
              v-for="a in collections"
              @click.native="segments[selectedSegment].nft_address = a.nft_address"
            >
              {{ a.nft_address }}
            </CDropdownItem>
          </CDropdown>
          <div 
            class="generic-button"
            @click="addComponent(-1, 'minter')"
          >
              Add Minter
          </div>
        </div>
      </template>
      <template #footer>
        <div class="hidden" />
      </template>
    </CModal>

    <CModal
      v-if="showLauncherModal"
      color="#995dd4"
      width="medium"
      @close="showLauncherModal = false"
    >
      <template #header>
        <div> {{ localize('SET_NFT_ADDRESS') }} </div>
      </template>
      <template #body>
        <div>
          <CDropdown
            title="Set NFT Address"
            :toggler-text="segments[selectedSegment].nft_address"
            class="nft-address-dropdown-item"
          >
            <CDropdownItem
              v-for="a in collections"
              @click.native="segments[selectedSegment].nft_address = a.nft_address"
            >
              {{ a.nft_address }}
            </CDropdownItem>
          </CDropdown>
          <div 
            class="generic-button"
            @click="addComponent(-1, 'launcher')"
          >
            Add Launcher
          </div>
        </div>
      </template>
      <template #footer>
        <div class="hidden" />
      </template>
    </CModal>

    <CModal
      v-if="showCollectionsModal"
      color="#995dd4"
      width="medium"
      @close="showCollectionsModal = false"
    >
      <template #header>
        <div> {{ localize('CREATE_TOKENS_MODAL_TITLE') }} </div>
      </template>
      <template #body>
        <div>
          <CollectionPreview
            v-for="a in collections"
            class="mid-section"
            :collection="a"
            @click="gotoCollection(a.nft_address)"
          />
        </div>
      </template>
      <template #footer>
        <div class="hidden" />
      </template>
    </CModal>

    <CModal
      v-if="createTokenModal"
      color="#995dd4"
      width="medium"
      @close="createTokenModal = false"
    >
      <template #header>
        <div> {{ localize('CREATE_TOKENS_MODAL_TITLE') }} </div>
      </template>
      <template #body>
        <div class="flex-col">
<!--
          <div class="flex-row">
            <div
              class="erc-button-outer"
            >
              <div
                class="erc-button-inner"
                @click="gotoTab('create-erc-20')"
              >
                NFT ERC-20
              </div>
            </div>
            <div class="erc-description">
              {{ localize('CREATE_ERC20') }}
            </div>
          </div>
-->
          <div class="flex-row">
            <div
              class="erc-button-outer"
            >
              <div
                class="erc-button-inner"
                @click="gotoTab('create-erc-721')"
              >
                NFT ERC-721
              </div>
            </div>
            <div class="erc-description">
              {{ localize('CREATE_ERC721') }}
            </div>
          </div>
<!--
          <div class="flex-row">
            <div
              class="erc-button-outer"
            >
              <div
                class="erc-button-inner"
                @click="gotoTab('create-erc-1155')"
              >
                NFT ERC-1155
              </div>
            </div>
            <div class="erc-description">
              {{ localize('CREATE_ERC1155') }}
            </div>
          </div>
-->
        </div>
      </template>
      <template #footer>
        <div class="hidden" />
      </template>
    </CModal>

    <CModal
      v-if="saveFolioModal"
      color="#995dd4"
      @close="closeSaveFolioModal"
    >
      <template #header>
        <div> Save Folio </div>
      </template>
      <template #body>
        {{ saveFolioModalMessage }}
      </template>
      <template #footer>
        <div class="hidden" />
      </template>
    </CModal>

    <CModal
      v-if="openSmModal"
      color="#995dd4"
      width="large"
      @close="openSmModal = false"
    >
      <template #header>
        <div> Create Post </div>
      </template>
      <template #body>
        <div class="creator-modal-edit">
          <input
            v-model="sm.website"
            class="text-input"
            placeholder="Website"
            type="text"
          >
          <input
            v-model="sm.instagram"
            class="text-input"
            placeholder="Instagram"
            type="text"
          >
          <input
            v-model="sm.twitter"
            class="text-input"
            placeholder="Twitter"
            type="text"
          >
          <input
            v-model="sm.twitch"
            class="text-input"
            placeholder="Twitch"
            type="text"
          >
          <input
            v-model="sm.youtube"
            class="text-input"
            placeholder="Youtube"
            type="text"
          >
          <div class="flex-row">
            <div
              class="save-creator"
              @click="saveSm"
            >
              {{ localize('TEXT_SAVE') }}
            </div>
            <div
              class="close-edit"
              @click="openSmModal = false"
            >
              Close
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="hidden" />
      </template>
    </CModal>

    <CModal
      v-if="createPostModal"
      color="#995dd4"
      width="large"
      @close="closeCreatePostModal"
    >
      <template #header>
        <div> Create Post </div>
      </template>
      <template #body>
        <CreatePost
          :pid="currentPost.pid"
          :preview="currentPost.preview"
          :content="currentPost.content"
          @close="createPostEvent"
        >
          Post Update
        </CreatePost>
      </template>
      <template #footer>
        <div class="hidden" />
      </template>
    </CModal>

    <CModal
      v-if="setTextModal"
      width="medium"
      color="#995dd4"
      @close="setTextModal = false"
    >
      <template #header>
        <div> {{ localize('TEXT_EDIT') }} </div>
      </template>
      <template #body>
        <div class="creator-modal-edit">
          <input
            v-model="input.title"
            class="text-input"
            placeholder="Title"
            type="text"
          >
          <textarea
            v-model="input.about"
            class="text-input"
            placeholder="description"
            type="text"
          />
          <div
            class="close-edit"
            @click="setTextModal = false"
          >
            Close
          </div>
        </div>
      </template>
      <template #footer>
        <div class="hidden" />
      </template>
    </CModal>

    <CModal
      v-if="showWaitModal"
      width="small"
      @close="showWaitModal = false"
    >
      <template #header>
        <div> {{ お待ちください }} </div>
      </template>
      <template #body>
        <div>
          お待ちください
        </div>
      </template>
      <template #footer>
        <div class="hidden" />
      </template>
    </CModal>

  </div>
</template>
<script>

import {inject, onMounted, reactive, ref, watch} from "vue";
import {translationStore} from "@/stores/translation";
import CollectionPreview from "./CollectionPreview.vue";
import CreatorFolio from "./CreatorFolio.vue";
import CreatorPost from "./CreatorPost.vue";
import CreatePost from "./CreatePost.vue";
import Doodle from "./Doodle.vue";
import ProjectComponent from "./ProjectComponent.vue";
import Axios from "axios";
import {useRoute, useRouter} from "vue-router";

export default {
    "name": "Creator",
    "components": {
        CollectionPreview,
        CreatorFolio,
        CreatorPost,
        CreatePost,
        ProjectComponent,
        Doodle
    },
    setup () {

        const translation = translationStore(),
            router = useRouter(),
            route = useRoute(),
            creatorposts = reactive([]),
            userInfo = inject("userInfo"),
            offset = ref(0),
            doodle = ref(),
            doodleId = ref(0),
            collections = reactive([]),
            input = reactive({
                "title": "ShinoVi",
                "about": "",
                "image": "",
                "video": "",
                "videoThumbnail": "",
                "effect": "",
                "doodle": "",
                "primaryColor": "#fff0fa",
                "secondaryColor": "#dbd0e6",
                "n": 0
            }),
            sm = reactive({
                "website": "",
                "instagram": "",
                "twitter": "",
                "twitch": "",
                "youtube": ""
            }),
            swatches = [
                "#efab93",
                "#ee827c",
                "#ec6d71",
                "#e95295",
                "#d7003a",
                "#e60033",
                "#fef263",
                "#ec6d51",
                "#f8b500",
                "#ffd900",
                "#ffdb4f",
                "#e6b422",
                "#dccb18",
                "#c3d825",
                "#69821b",
                "#316745",
                "#007b43",
                "#028760",
                "#a0d8ef",
                "#89c3eb",
                "#00a3af",
                "#2ca9e1",
                "#19448e",
                "#165e83",
                "#dbd0e6",
                "#cc7eb1",
                "#674598",
                "#4d4398",
                "#55295b",
                "#884898"
            ],
            serverConfig = inject("serverConfig"),
            setTextModal = ref(false),
            createTokenModal = ref(false),
            selectedSegment = ref(),
            showDoodleModal = ref(false),
            showWaitModal = ref(false),
            showCollectionsModal = ref(false),
            showLauncherModal = ref(false),
            showMinterModal = ref(false),
            openSmModal = ref(false),
            createPostModal = ref(false),
            saveFolioModal = ref(false),
            saveFolioModalMessage = ref(""),
            segments = reactive([]),
            creatorpostsClass = ref("creatorposts-desktop"),
            owner = ref(""),
            currentPost = reactive({

                "pid": "",
                "preview": {},
                "conetnt": ""

            }),
            isOwner = ref(false),
            addSegment = function () {

                let segment = {}
                segment.orientation = "row";
                segment.components = [];
                segment.video  = "";
                segment.poster  = "";
                segment.image  = "";
                segments.push(segment);

            },
            addComponent = function (i, t) {

                if (i >= 0) {

                    selectedSegment.value = i;

                }

                segments[selectedSegment.value].components.push({
                    orientation: "cmp-row",
                    video:  "",
                    poster: "",
                    image: "",
                    nft_address: segments[selectedSegment.value].nft_address,
                    backgroundImage: "",
                    backgroundVideo: "",
                    class: t
                });
                showLauncherModal.value = false;
                showMinterModal.value = false;

            },
            setBackgroundColor = function (i,j) {

                if (j >= 0) {

                    if (segments[i].components[j].backgroundColorA && segments[i].components[j].backgroundColorB) {

                        segments[i].components[j].height = "";
                        segments[i].components[j].background = "linear-gradient(45deg," + segments[i].components[j].backgroundColorA + "," + segments[i].components[j].backgroundColorB + ")";

                    } else if (segments[i].components[j].backgroundColorA) {

                        segments[i].components[j].backgroundColor = segments[i].components[j].backgroundColorA;

                    }

                } else {

                    if (segments[i].backgroundColorA && segments[i].backgroundColorB) {

                        segments[i].height = "";
                        segments[i].background = "linear-gradient(45deg," + segments[i].backgroundColorA + "," + segments[i].backgroundColorB + ")";

                    } else if (segments[i].backgroundColorA) {

                        segments[i].backgroundColor = segments[i].backgroundColorA;

                    }

                }

            },
            setSegmentOrientation = function (i,o) {

                segments[i].orientation = o;

            },
            getOrientation = function(o) {

                if (serverConfig.view === "tablet" || serverConfig.view === "mobile") {

                    return "flex-col-seg";

                }

                if (o === "column") {

                    return "flex-col-seg";

                } else {

                    return "flex-row-seg";

                }

            },
            editPost = function (p) {

                currentPost.pid = p.id;
                currentPost.preview = p.preview;
                currentPost.content = p.content;
                createPostModal.value = true;

            },
            isEcommerceEnabled = function (p) {

                console.log("`${process.env.VUE_APP_ECOMMERCE_MODULE}`");
                console.log(`${process.env.VUE_APP_ECOMMERCE_MODULE}`);
                console.log(process.env.VUE_APP_ECOMMERCE_MODULE);

                if (`${process.env.VUE_APP_ECOMMERCE_MODULE}` === true) {

                    return true;

                }

                return false;


            },
            gotoCollection = function (address) {

                router.push({"path": `/collection/${address}/`}).catch((err) => {

                    throw err;

                });

            },
            showModal = function (i,t) {

                selectedSegment.value = i;

                if (t === 'launcher') {

                    showLauncherModal.value = true;

                } else if (t === 'minter') {

                    showMinterModal.value = true;

                }

            },
            gotoTab = function (t) {

                router.push({"name": t,
                    "path": `/${t}`}).catch((err) => {

                    throw err;

                });

            },
            gotoUrl = function (url) {

                window.open(url);

            },
            videoTypes = reactive([
                "video/mp4",
                "video/mpeg",
                "video/x-msvideo",
                "video/webm"
            ]),
            imageTypes = reactive([
                "image/gif",
                "image/jpeg",
                "image/png",
                "image/svg",
                "image/webp"
            ]),
            file = ref(null),
            maxVideoSize = ref("1000000000"),
            maxImageSize = ref("10000000"),
            closeCreatePostModal = function () {

                createPostModal.value = false;
                creatorposts.value = [];
                getPosts();

            },
            closeSaveFolioModal = function () {

                saveFolioModal.value = false;

            },
            setCreatorBackdrop = function (e) {

                input.video = "";
                input.image = "";

                const files = e.target.files;
                file.value = files[0];

                if (imageTypes.indexOf(files[0].type) > -1) {

                    console.log("supported image type");
                    getMediaDimensions(
                        files[0],
                        "image",
                        (d) => {

                            file.value.dimensions = d;
                            input.video = "";
                            input.image = URL.createObjectURL(files[0]);

                        }
                    );

                } else if (videoTypes.indexOf(files[0].type) > -1) {

                    console.log("supported video type");
                    getMediaDimensions(
                        files[0],
                        "video",
                        (d) => {

                            file.value.dimensions = d;
                            input.image = "";
                            input.video = URL.createObjectURL(files[0]);

                        }
                    );

                } else {

                    console.log("not a supported type");

                }

            },
            setBackdrop = function (e,i,j) {

                let file_ = e.target.files[0];

                if (imageTypes.indexOf(file_.type) > -1) {

                    console.log("supported image type");
                    getMediaDimensions(
                        file_,
                        "image",
                        (d) => {

                            file_.dimensions = d;
                            addFile(file_, file_.type, (r)=> {

                                if (j >= 0) {

                                    segments[i].components[j].background = "";
                                    segments[i].components[j].backgroundColor = "";
                                    segments[i].components[j].backgroundUrl = r.url;
                                    segments[i].components[j].backgroundPreview = "";
                                    segments[i].components[j].backgroundVideo = "";
                                    segments[i].components[j].backgroundImage = r.url;

                                } else {

                                    segments[i].background = "";
                                    segments[i].backgroundColor = "";
                                    segments[i].url = r.url;
                                    segments[i].preview = "";
                                    segments[i].video = "";
                                    segments[i].image = r.url;

                                }

                            })

                        }
                    );

                } else if (videoTypes.indexOf(file_.type) > -1) {

                    console.log("supported video type");
                    getMediaDimensions(
                        file_,
                        "video",
                        (d) => {

                            file_.dimensions = d;
                            addFile(file_, file_.type, (r)=> {

                                if (j >= 0) {

                                    segments[i].components[j].background = "";
                                    segments[i].components[j].backgroundColor = "";
                                    segments[i].components[j].backgroundUrl = r.url;
                                    segments[i].components[j].backgroundPreview = r.media_filename_preview
                                    segments[i].components[j].backgroundImage = "";
                                    segments[i].components[j].backgroundVideo = r.url;

                                } else {

                                    segments[i].background = "";
                                    segments[i].backgroundColor = "";
                                    segments[i].url = r.url;
                                    segments[i].preview = r.media_filename_preview
                                    segments[i].image = "";
                                    segments[i].video = r.url;

                                }

                            })

                        }
                    );

                } else {

                    console.log("not a supported type");

                }

            },
            setBorder = function (i, j, o) {

                if (j >= 0) {

                    segments[i].components[j].borderValue = o;

                    if (!segments[i].components[j].borderColor) {

                        segments[i].components[j].borderColor = "#fff";

                    }

                    if (o === "none") {

                        segments[i].components[j].border = "";

                    } else if (o === "light") {

                        segments[i].components[j].border = "2px solid " + segments[i].components[j].borderColor;

                    } else if (o === "medium") {

                        segments[i].components[j].border = "4px solid " + segments[i].components[j].borderColor;

                    } else if (o === "strong") {

                        segments[i].components[j].border = "7px solid " + segments[i].components[j].borderColor;

                    } else if (o === "extreme") {

                        segments[i].components[j].border = "15px solid " + segments[i].components[j].borderColor;

                    }

                } else {

                    segments[i].borderValue = o;

                    if (!segments[i].borderColor) {

                        segments[i].borderColor = "#fff";

                    }

                    if (o === "none") {

                        segments[i].border = "";

                    } else if (o === "light") {

                        segments[i].border = "2px solid " + segments[i].borderColor;

                    } else if (o === "medium") {

                        segments[i].border = "4px solid " + segments[i].borderColor;

                    } else if (o === "strong") {

                        segments[i].border = "7px solid " + segments[i].borderColor;

                    } else if (o === "extreme") {

                        segments[i].border = "15px solid " + segments[i].borderColor;

                    }

                }

            },
            setBorderStyle = function (i, j, o) {

                if (j >= 0) {

                    segments[i].components[j].borderStyleValue = o;

                    if (o === "none") {

                        segments[i].components[j].angularBorderStyle = "";
                        segments[i].components[j].borderRadius = "";

                    } else if (o === "round-light") {

                        segments[i].components[j].angularBorderStyle = "";
                        segments[i].components[j].borderRadius = "10px";

                    } else if (o === "round-medium") {

                        segments[i].components[j].angularBorderStyle = "";
                        segments[i].components[j].borderRadius = "25px";

                    } else if (o === "round-strong") {

                        segments[i].components[j].angularBorderStyle = "";
                        segments[i].components[j].borderRadius = "50px";

                    } else if (o === "round-extreme") {

                        segments[i].components[j].angularBorderStyle = "";
                        segments[i].components[j].borderRadius = "100px";

                    } else if (o === "angular-a") {

                        segments[i].components[j].borderRadius = "";
                        segments[i].components[j].angularBorderStyle = "polygon(30px 0%, calc(100% - 30px) 0%, 100% 30px, 100% calc(100% - 30px), calc(100% - 30px) 100%, 30px 100%, 0% calc(100% - 30px), 0% 30px);";

                    } else if (o === "angular-b") {

                        segments[i].components[j].borderRadius = "";
                        segments[i].components[j].angularBorderStyle = "polygon(30px 0, 100% 0,100% 10px,100% calc(100% - 30px),calc(100% - 30px) 100%,0px 100%,0 100%,0 30px);"

                    } else if (o === "angular-c") {

                        segments[i].components[j].borderRadius = "";
                        segments[i].components[j].angularBorderStyle = "polygon(0px 100%,0 100%,0 30px, 30px 0, 100% 0,100% 10px,100% calc(100% - 30px),calc(100% - 30px) 100%);"

                    }

                } else {

                    segments[i].borderStyleValue = o;

                    if (o === "none") {

                        segments[i].angularBorderStyle = "";
                        segments[i].borderRadius = "";

                    } else if (o === "round-light") {

                        segments[i].angularBorderStyle = "";
                        segments[i].borderRadius = "10px";

                    } else if (o === "round-medium") {

                        segments[i].angularBorderStyle = "";
                        segments[i].borderRadius = "25px";

                    } else if (o === "round-strong") {

                        segments[i].angularBorderStyle = "";
                        segments[i].borderRadius = "50px";

                    } else if (o === "round-extreme") {

                        segments[i].angularBorderStyle = "";
                        segments[i].borderRadius = "100px";

                    } else if (o === "angular-a") {

                        segments[i].borderRadius = "";
                        segments[i].angularBorderStyle = "polygon(30px 0%, calc(100% - 30px) 0%, 100% 30px, 100% calc(100% - 30px), calc(100% - 30px) 100%, 30px 100%, 0% calc(100% - 30px), 0% 30px);";

                    } else if (o === "angular-b") {

                        segments[i].borderRadius = "";
                        segments[i].angularBorderStyle = "-webkit-clip-path: polygon(30px 0, 100% 0,100% 10px,100% calc(100% - 30px),calc(100% - 30px) 100%, 0px 100%, 0 100%,0 30px);"

                    } else if (o === "angular-c") {

                        segments[i].borderRadius = "";
                        segments[i].angularBorderStyle = "polygon(0px 100%,0 100%,0 30px, 30px 0, 100% 0,100% 10px,100% calc(100% - 30px),calc(100% - 30px) 100%);"

                    }

                }

            },
            setBoxShadow = function (i, j, o) {

                var shadowColor;

                if (j >= 0) {

                    if (!segments[i].components[j].borderColor) {

                        shadowColor = "#747474";

                    } else {

                        shadowColor = segments[i].components[j].borderColor;

                    }

                    segments[i].components[j].boxShadowValue = o;

                    if (o === "none") {

                        segments[i].components[j].boxShadow = "";

                    } else if (o === "light") {

                        segments[i].components[j].boxShadow = "0px 0px 10px 1px " + shadowColor;

                    } else if (o === "medium") {

                        segments[i].components[j].boxShadow = "0px 0px 25px 2px " + shadowColor;

                    } else if (o === "strong") {

                        segments[i].components[j].boxShadow = "0px 0px 50px 3px " + shadowColor;

                    }

                } else {

                    if (!segments[i].borderColor) {

                        shadowColor = "#747474";

                    } else {

                        shadowColor = segments[i].borderColor;

                    }

                    segments[i].boxShadowValue = o;

                    if (o === "none") {

                        segments[i].boxShadow = "";

                    } else if (o === "light") {

                        segments[i].boxShadow = "0px 0px 10px 1px " + shadowColor;

                    } else if (o === "medium") {

                        segments[i].boxShadow = "0px 0px 25px 2px " + shadowColor;

                    } else if (o === "strong") {

                        segments[i].boxShadow = "0px 0px 50px 3px " + shadowColor;

                    }

                }

            },
            setMargin = function (i, j, o) {

                if (j >= 0) {

                    segments[i].components[j].marginValue = o;

                    if (o === "none") {

                        segments[i].components[j].margin = "";

                    } else if (o === "small") {

                        segments[i].components[j].margin = "10px 10px 10px 10px";

                    } else if (o === "medium") {

                        segments[i].components[j].margin = "20px 20px 20px 20px";

                    } else if (o === "large") {

                        segments[i].components[j].margin = "50px 30px 50px 30px";

                    }

                } else {

                    segments[i].marginValue = o;

                    if (o === "none") {

                        segments[i].margin = "";

                    } else if (o === "small") {

                        segments[i].margin = "10px 10px 10px 10px";

                    } else if (o === "medium") {

                        segments[i].margin = "20px 20px 20px 20px";

                    } else if (o === "large") {

                        segments[i].margin = "50px 30px 50px 30px";

                    }

                 }

            },
            setOrientation = function (data) {

                let o = data.orientation.value;
                let i = data.segmentId.value;
                let j = data.componentId.value;

                segments[i].components[j].orientation = o;

            },
            setComponentImage = function (data) {

                let e = data.e;
                let i = data.segmentId.value;
                let j = data.componentId.value;

                let file_ = e.target.files[0];

                if (imageTypes.indexOf(file_.type) > -1) {

                    console.log("supported image type");
                    getMediaDimensions(
                        file_,
                        "image",
                        (d) => {

                            file_.dimensions = d;
                            addFile(file_, file_.type, (r)=> {

                                segments[i].components[j].url = r.url;
                                segments[i].components[j].preview = "";
                                segments[i].components[j].video = "";
                                segments[i].components[j].image = r.url;

                            })

                        }
                    );

                } else if (videoTypes.indexOf(file_.type) > -1) {

                    console.log("supported video type");
                    getMediaDimensions(
                        file_,
                        "video",
                        (d) => {

                            file_.dimensions = d;
                            addFile(file_, file_.type, (r)=> {

                                segments[i].components[j].url = r.url;
                                segments[i].components[j].preview = r.media_filename_preview
                                segments[i].components[j].image = "";
                                segments[i].components[j].video = r.url;

                            })

                        }
                    );

                } else {

                    console.log("not a supported type");

                }

            },
            setDoodle = function (e) {

                doodle.value = e;
                doodleId.value++;

            },
            saveDoodle = function (e) {

                input.doodle = btoa(doodle.value)
                saveCreatorFolio();

            },
            removeComponent = function (data) {

                let e = data.e;
                let i = data.segmentId.value;
                let j = data.componentId.value;

                segments[i].components.splice(j, 1);

            },
            removeBackdrop  = function (i,j) {

                if (j >= 0) {

                    segments[i].components[j].video = "";
                    segments[i].components[j].background = "";
                    segments[i].components[j].backgroundImage = "";
                    segments[i].components[j].backgroundVideo = "";
                    segments[i].components[j].backgroundColor = "";
                    segments[i].components[j].height = "auto";

                } else {

                    segments[i].image = "";
                    segments[i].video = "";
                    segments[i].background = "";
                    segments[i].backgroundColor = "";
                    segments[i].backgroundColorA = "";
                    segments[i].backgroundColorB = "";
                    segments[i].height = "auto";

                }

            },
            removeSegment  = function (i) {

                segments.splice(i,1)

            },
            moveSegmentUp = function (i) {

                if (i > 0) {

                    const temp = segments[i];
                    segments[i] = segments[i - 1];
                    segments[i - 1] = temp;
                    showWaitModal.value = true;
                    setTimeout(()=> {
                        window.location.reload();
                    }, 5000);

                }

            },
            moveSegmentDown  = function (i) {

                if (i < segments.length) {

                    const temp = segments[i];
                    segments[i] = segments[i + 1];
                    segments[i + 1] = temp;
                    showWaitModal.value = true;
                    setTimeout(()=> {
                        window.location.reload();
                    }, 3000);

                }

            },
            getMediaDimensions = async function (f, t, c) {

                const dimensions = {};
                if (t === "video") {

                    const $video = document.createElement("video");
                    $video.src = URL.createObjectURL(f);
                    $video.addEventListener(
                        "loadedmetadata",
                        function () {

                            dimensions.x = this.videoWidth;
                            dimensions.y = this.videoLength;
                            c(dimensions);

                        }
                    );
                    setTimeout(
                        () => {

                            $video.removeEventListener(
                                "loadedmetadata",
                                () => {},
                                false
                            );

                        },
                        3000
                    );

                } else if (t === "image") {

                    const img = new Image();
                    img.src = URL.createObjectURL(f);
                    img.onload = function () {

                        dimensions.x = img.width;
                        dimensions.y = img.height;
                        c(dimensions);

                    };

                } else {

                    c();

                }

            },
            addFile = function (file, t, callback) {

                var data = new FormData();

                data.append(
                    "file",
                    file
                );
                data.append(
                    "type",
                    t
                );

                            //"Content-Type": "application/json"
                Axios.post(

                    `${process.env.VUE_APP_SERVER_URI}addMediaFile`,
                    data,
                    {

                        "headers": {
                            "Content-Type": "multipart/form-data"
                        }

                    }

                ).
                    then((response) => {

                        callback(response.data)

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            saveCreatorFolio = function () {

                input.file = file;
                input.name = route.path.slice(
                    1,
                    -1
                );

console.log('file')
console.log(file)

                Axios.post(

                    `${process.env.VUE_APP_SERVER_URI}addFolio`,
                    input,
                    {

                        "headers": {
                            "Content-Type": "multipart/form-data"
                        }

                    }

                ).
                    then((response) => {

                        if (response.status === 200) {

                            saveFolioModal.value = true;
                            saveFolioModalMessage.value = translation.localize("SAVE_MODAL_MESSAGE_SUCCESS");

                        } else {

                            saveFolioModal.value = true;
                            saveFolioModalMessage.value = translation.localize("SAVE_MODAL_MESSAGE_FAILED");

                        }

                    }).
                    catch((error) => {

                        saveFolioModal.value = true;
                        saveFolioModalMessage.value = translation.localize("SAVE_MODAL_MESSAGE_FAILED");
                        throw error;

                    });

            },
            saveSm = function (e) {

                Axios.get(

                    `${process.env.VUE_APP_SERVER_URI}updateSocialMedia`,
                    {
                        "params": {
                            "name": route.path.slice(
                                1,
                                -1
                            ),
                            "website": sm.website,
                            "instagram": sm.instagram,
                            "twitter": sm.twitter,
                            "twitch": sm.twitch,
                            "youtube": sm.youtube
                        }
                    }

                ).
                    then((response) => {

                        if (response.status === 200) {

                            openSmModal.value = false;

                        }

                    });

            },

            getCollections = function (e) {

                Axios.get(

                    `${process.env.VUE_APP_SERVER_URI}getCollectionsByCreator`,
                    {
                        "params": {

                            "name": route.path.slice(
                                1,
                                -1
                            )

                        }

                    }

                ).
                    then((response) => {

                        if (response.data) {

                            for (let i = 0; i < response.data.length; i++) {

                                if (response.data[i].image && response.data[i].name && response.data[i].symbol && response.data[i].banner) {

                                    collections.push(response.data[i]);
                                    collections[collections.length - 1].primaryColor = response.data[i].primary_color;
                                    collections[collections.length - 1].secondaryColor = response.data[i].secondary_color;

                                }

                            }

                        }

                    });

            },


            getPosts = function (e) {

                creatorposts.splice(0);

                Axios.get(

                    `${process.env.VUE_APP_SERVER_URI}getPosts`,
                    {
                        "params": {
                            "name": route.path.slice(
                                1,
                                -1
                            ),
                            offset
                        }
                    }
                ).
                    then((response) => {

                        if (response.status === 200) {

                            for (let i = 0; i < response.data.length; i++) {

                                creatorposts.push(response.data[i]);

                            }
                            offset.value = creatorposts.length;

                            if (route.hash) {

                                setTimeout(
                                    () => {

                                        document.getElementById(`content-${route.hash.substring(1)}`).scrollIntoView({

                                            "behavior": "smooth"

                                        });

                                    },
                                    3
                                );

                            }

                        }

                    });

            },
            getFolio = function (e) {

                Axios.get(

                    `${process.env.VUE_APP_SERVER_URI}getFolio`,
                    {
                        "params": {
                            "name": route.path.slice(
                                1,
                                -1
                            )
                        }
                    }
                ).
                    then((response) => {

                        if (response.status === 200) {

                            input.title = response.data.title;
                            input.about = response.data.about;
                            if (response.data.image) {

                                input.image = response.data.image;

                            }
                            if (response.data.video) {

                                input.video = response.data.video;

                            }
                            if (response.data.video_thumbnail) {

                                input.videoThumbnail = response.data.video_thumbnail;

                            }
                            if (response.data.effect) {

                                input.effect = response.data.effect;

                            }
                            if (response.data.primary_color) {

                                input.primaryColor = response.data.primary_color;

                            }
                            if (response.data.secondary_color) {

                                input.secondaryColor = response.data.secondary_color;

                            }
                            owner.value = response.data.user_id;

                            if (!response.data.video && !response.data.image) {

                                input.image = `${process.env.VUE_APP_SERVER_URI}public/folio/default.jpg`;

                            }

                            if (response.data.doodle) {

                                try {

                                    doodle.value = atob(response.data.doodle);
                                    doodleId.value++;

                                } catch (e) {

                                    console.log(e);

                                }

                            }

                            // socialmedia
                            sm.website = response.data.website;
                            sm.instagram = response.data.instagram;
                            sm.twitter = response.data.twitter;
                            sm.twitch = response.data.twitch;
                            sm.youtube = response.data.youtube;

                            updateView();

                            if (owner.value === userInfo.user_id) {

                                isOwner.value = true

                            } else {

                                isOwner.value = false

                            }

                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            createPostEvent = function () {

                createPostModal.value = false;
                creatorposts.value = [];
                offset.value = 0;
                getPosts();

            },
            autoSave = function () {

                let data = JSON.stringify(segments),

                    doLoop = () => {

                        if (data != JSON.stringify(segments)) {

                            data = JSON.stringify(segments);
                            saveProject(data, (r)=>{console.log(r)});

                        }

                        setTimeout(
                            () => {

                                doLoop();

                            },
                            3000
                        );

                    };
                doLoop();

            },
            saveProject = function (ds, callback) {

                let data = {

                    segments: ds,
                    name: route.params.page

                }

                Axios.post(
                    `${process.env.VUE_APP_SERVER_URI}saveProject`,
                    data,
                    {

                        "headers": {

                            "Content-Type": "application/json"

                        }

                    }
                ).
                    then((response) => {

                        if (response.status === 200) {

                            callback(response.data);

                        } else {

                            callback(response);

                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            loadProject = function (callback) {

                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}loadProject`,
                    {
                        "params": {

                            name: route.params.page

                        }
                    },
                    {

                        "headers": {

                            "Content-Type": "application/json"

                        }

                    }
                ).
                    then((response) => {

                        if (response.status === 200) {

                            for (var i in response.data.segments) {

                                if (response.data.segments[i]) {

                                    if (response.data.segments[i].components) {

                                        for (var j=0; j < response.data.segments[i].components.length; j++) {

                                            if (!response.data.segments[i].components[j]) {

                                                response.data.segments[i].splice(j,1);

                                            }

                                        }

                                    }

                                }

                                if (response.data.segments[i])
                                  segments.push(response.data.segments[i]);

                            }
                            //segments.value = response.data.segments;

                        }

                });

            },
            updateView = function () {

                if (serverConfig.view === "desktop") {

                    creatorpostsClass.value = "creatorposts-desktop";

                } else if (serverConfig.view === "laptop") {

                    creatorpostsClass.value = "creatorposts-desktop";

                } else if (serverConfig.view === "tablet") {

                    creatorpostsClass.value = "creatorposts-desktop";

                } else if (serverConfig.view === "mobile") {

                    creatorpostsClass.value = "creatorposts-mobile";

                } else {

                    creatorpostsClass.value = "creatorposts-mobile";

                }

                for (var i=0; i < segments.length; i++) {

                    var sbg = document.getElementById("segment-backdrop-" + i);

                    var si = document.getElementById("segment-image-" + i);
                    if (si) {

                        let height = si.offsetHeight + "px;"
                        segments[i].height = height;

                    }

                    var sv = document.getElementById("segment-video-" + i);
                    if (sv) {

                        let height = sv.offsetHeight + "px;"
                        segments[i].height = height;

                    }

                }

            };

        onMounted(() => {

            getCollections();

            watch(
                () => serverConfig.view,

                (first, second) => {

                    updateView();

                }

            );

            setTimeout(
                () => {

                    getPosts();

                },
                1000
            );

            setTimeout(
                () => {

                    getFolio();

                },
                1000
            );

            autoSave();
            loadProject();

        });

        return {"localize": translation.localize,
            input,
            addFile,
            sm,
            saveSm,
            autoSave,
            segments,
            editPost,
            addComponent,
            addSegment,
            setSegmentOrientation,
            setBackgroundColor,
            setComponentImage,
            setDoodle,
            saveDoodle,
            setOrientation,
            doodle,
            doodleId,
            removeComponent,
            removeBackdrop,
            removeSegment,
            moveSegmentUp,
            moveSegmentDown,
            currentPost,
            createTokenModal,
            closeCreatePostModal,
            closeSaveFolioModal,
            collections,
            getCollections,
            getOrientation,
            setMargin,
            setBorder,
            setBorderStyle,
            setBoxShadow,
            isEcommerceEnabled,
            creatorposts,
            createPostEvent,
            offset,
            userInfo,
            owner,
            getMediaDimensions,
            getFolio,
            getPosts,
            gotoUrl,
            gotoTab,
            gotoCollection,
            setCreatorBackdrop,
            setBackdrop,
            setTextModal,
            openSmModal,
            createPostModal,
            loadProject,
            saveProject,
            saveFolioModal,
            saveFolioModalMessage,
            selectedSegment,
            showModal,
            showWaitModal,
            showDoodleModal,
            showMinterModal,
            showLauncherModal,
            showCollectionsModal,
            updateView,
            imageTypes,
            videoTypes,
            maxVideoSize,
            maxImageSize,
            isOwner,
            swatches,
            saveCreatorFolio,
            creatorpostsClass,
            serverConfig};

    }
};

</script>
<style scoped>
h2 {
  margin: 10px;
  text-align: left;
  font-size: 2.0em;
  font-weight: bold;
}
.content-container-outer {
  position: relative;
  display: flex;
  flex-direction: column;
}
.content-container-inner {
  display: flex;
  flex-direction: column;
}
.generic-button {
  display: flex;
  justify-content: center;
  font-family: Comfortaa,sans-serif;
  text-align: left;
  white-space: nowrap;
  color: #545454;
  padding: 10px;
  margin: 10px 0px 10px 10px;
  border-radius: 3px;
  font-size: 0.9em;
  border: 1px solid #efefef;
  cursor: pointer;
  background: #ffffff;
}
.plus-icon {
}
.subtitle {
  max-width: 500px;
  padding: 20px;
  font-size: 3em;
  font-size: 1.0em;
  line-height: 30px;
  text-align: left;
}
a.contactus {
  color: #322e6a;
  text-decoration: underline;
  cursor: pointer;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-row-seg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
}
.flex-col-seg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.edit-section-buttons {
  border-radius: 5px;
  display: flex;
  padding: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 1;
  background: #82929d;
  color: white;
  flex-wrap: wrap;
}
.creatorposts-mobile {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  border-radius: 15px;
  box-shadow: 0px 10px 20px 10px rgb(0 0 0 / 10%), 0 8px 10px -6px rgb(0 0 0 / 10%);
}
.creatorposts-desktop {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 15px;
  box-shadow: 0px 10px 20px 10px rgb(0 0 0 / 10%), 0 8px 10px -6px rgb(0 0 0 / 10%);
}
.creatorposts-desktop {
  display: flex;
  flex-direction: column;
}
.creator-create-text {
  margin: 2px 2px 2px 10px;
}
.edit-custom-field {
  white-space: nowrap;
  text-align: left;
  font-size: .75em;
  font-weight: 700;
  color: #afafaf;
}
.edit-background-field {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
.edit-creator-color {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.swatch-style {
  color: white;
  width: 24px;
  height: 24px
}
.creatorfolio-add-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.sm-website {
  margin: 5px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  background-size: cover;
  background-image: url("../assets/website64.png")
}
.sm-twitter {
  margin: 5px;
  width: 38px;
  height: 32px;
  cursor: pointer;
  background-size: cover;
  background-image: url("../assets/Twitter64.webp")
}
.sm-twitch {
  margin: 5px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  background-size: cover;
  background-image: url("../assets/th64.webp")
}
.sm-instagram {
  margin: 5px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  background-size: cover;
  background-image: url("../assets/isnta64.webp")
}
.sm-youtube {
  margin: 5px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  background-size: cover;
  background-image: url("../assets/yt64.webp")
}
.isrelative {
  position: relative;
}
#play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
}
#play-button:hover {
  cursor:pointer;
}
ul {
  margin: 10px;
}
li {
  margin: 0px;
}
.content-icon {
  margin-top: 20px;
  background-color: #937aeb;
  padding: 10px;
  border-radius: 50%;
  width: 100px;
}
.creatorfolio-edit-container {
  display: flex;
  align-items: end;
  z-index: 1;
}
.creatorfolio-edit-box {
  margin: 10px;
  font-size: 0.8em;
  padding: 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid #a5a5a5;
  border-radius: 5px;
  background-color: #ffffffd6;
}
.swatches-edit {
  display: flex;
  flex-direction: columns;
}
.swatches-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.project-container {
  width: 100%;
  min-height: 500px;
}
.section-container {
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}
.menu-small-click {
  color: white;
  font-size: 0.8em;
  margin: 5px;
  pointer: cursor;
}
.menu-small-click:hover {
  cursor: pointer;
  text-decoration: underline;
}
.normal-link {
  color: white;
  margin: 5px;
  font-size: 0.8em;
  cursor: pointer;
  font-weight: 400;
}
.normal-link:hover {
  text-decoration: underline;
}
.flex-edit-items {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px;
}
.add-buttons-wrapper {
  width: 170px;
  margin-left: 17px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 10px;
}
.margin-top-thirty {
  margin-top: 30px;
}
.item-title {
  white-space: nowrap;
  text-align: left;
  margin: 15px 0 0px 0;
  font-size: .75em;
  font-weight: 700;
  color: #afafaf;
}
.set-color-container {
  margin-bottom: 34px;
}
.margin-single-swatch {
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
}
.creatorfolio-add-container-right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.grow {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
}
.headerDesktop {
  max-width: 650px;
  margin: 10px;
  text-align: left;
  color: #322E6A;
}
.headerMobile {
  margin: 20px;
  max-width: 500px;
  font-size: 1em;
  text-align: center;
  color: #322E6A;
  z-index: 1;
}
.startHeader {
  width: 55%;
  padding: 10px;
  display: block;
  margin-top: 150px;
  margin-left: 20px;
  color: #322E6A;
  background-color: hsl(0deg 0% 100% / 70%);
}
.mid-section {
  width: 70%;
  max-width: 1600px;
  margin: 50px 10px;
  justify-content: center;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}
.fifty {
  width: 50%;
}
svg {
  width: 100%;
  max-height: 560px;
  z-index: 1;
}
img.creator-img-desktop {
  width: 100%;
  padding-left: 25%;
  z-index: -2;
}
img.creator-img-mobile {
  width: 100%;
  padding-left: 25%;
  z-index: -2;
}
#home {
  min-height: 1000px;
}
.spacer {
  width: 20px;
}
.text-left {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 60%;
  top: 0;
  bottom: 0;
  left: 0;
  text-align: left;
  padding-top: 100px;
}
.text-top {
  position: absolute;
  display: flex;
  align-items: flex-end;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  padding-top: 100px;
  text-align: left;
}
.blur-left {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 60%;
  //-webkit-mask: linear-gradient(to right,black calc(100% - 8rem),transparent 100%);
  //mask: linear-gradient(to right,black calc(100% - 8rem),transparent 100%);
  //-webkit-backdrop-filter: blur(0.5rem);
  backdrop-filter: blur(0.4em);
}
.blur-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  -webkit-mask: linear-gradient(to top,black calc(100% - 8rem),transparent 100%);
  mask: linear-gradient(to top,black calc(100% - 8rem),transparent 100%);
  -webkit-backdrop-filter: blur(0.5rem);
  backdrop-filter: blur(0.4em);
}
.darken-left {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 60%;
  -webkit-mask: linear-gradient(to right,#000000cc calc(100% - 8rem),transparent 100%);
  mask: linear-gradient(to right,black calc(100% - 8rem),transparent 100%);
  -webkit-backdrop-filter: blur(1rem);
  backdrop-filter: blur(1rem);
}
input#creator-file-upload {
  display: none;
}
label.creator-file-upload {
  display: block;
  margin: 5px;
  width: 26px;
  height: 24px;
  padding: 0;
  color: inherit;
  border: none;
  cursor: pointer;
  outline: inherit;
  background-image: url('../assets/upload-icon-small.png');
  background-size: contain;
  background-repeat: no-repeat;
}
label.creator-file-upload-dark {
  display: block;
  margin: 5px;
  width: 22px;
  height: 22px;
  padding: 0;
  color: inherit;
  border: none;
  cursor: pointer;
  outline: inherit;
  background-image: url('../assets/upload-icon-small.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.segment-backdrop {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
  background-position: 50%;
}
.segment-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  object-fit: contain;
  z-index: -1;
}
.segment-video {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  object-fit: contain;
  z-index: -1;
}
.segment-file-upload {
  display: none;
}
input {
  display: none;
}
label.segment-file-upload {
  display: block;
  margin: 5px;
  width: 26px;
  height: 24px;
  padding: 0;
  color: inherit;
  border: none;
  cursor: pointer;
  outline: inherit;
  background-image: url('../assets/upload-icon-small.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.edit-icon {
  display: block;
  margin: 5px;
  width: 26px;
  height: 24px;
  padding: 0;
  cursor: pointer;
  background-image: url('../assets/edit-icon.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.save-creator {
  display: flex;
  margin-top: 5px;
  justify-content: center;
  width: fit-content;
  font-family: Comfortaa,sans-serif;
  text-align: left;
  white-space: nowrap;
  color: #fff;
  font-weight: 500;
  padding: 10px;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  background: linear-gradient(338deg,#ff7c88,#ff46a4);
}
.creator-modal-edit {
  display: flex;
  flex-direction: column;
}
.close-edit {
  font-family: Comfortaa,sans-serif;
  text-align: left;
  font-size: 0.8em;
  margin-top: 20px;
  margin-left: 20px;
  color: ##525252;
}
.close-edit:hover {
  text-decoration: underline;
  cursor: pointer;
}
input.text-input {
  line-height: 30px;
  font-size: 0.8em;
  text-indent: 5px;
  padding: 5px;
  margin: 5px;
  border: none;
  border: 2px solid #995dd4;
  border-radius: 10px;
  outline: 0;
}
textarea.text-input {
  flex-grow: 1;
  line-height: 30px;
  font-size: 0.8em;
  text-indent: 5px;
  padding: 5px;
  margin: 5px;
  border: none;
  border: 2px solid #995dd4;
  border-radius: 10px;
  outline: 0;
}
.erc-button-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Comfortaa,sans-serif;
  text-align: left;
  white-space: nowrap;
  color: #545454;
  padding: 10px;
  min-width: 76px;
  border-radius: 8px;
  font-size: .8em;
  font-weight: bold;
  cursor: pointer;
  background: #fff;
  border: 2px solid #08102e5e;
}
.erc-button-outer {
  display: flex;
  justify-content: center;
  font-family: Comfortaa,sans-serif;
  text-align: left;
  white-space: nowrap;
  color: #545454;
  padding: 1px;
  margin: 10px 0 10px 10px;
  min-width: 100px;
  border-radius: 10px;
  font-size: .8em;
  font-weight: bold;
  cursor: pointer;
  background: #fff;
  border: 1px solid #08102e5e;
}
.erc-description {
  padding: 10px;
  font-size: 0.8em;
}
.orientation-dropdown-item {
  width: 100px;
}
.nft-address-dropdown-item {
  width: 455px;
}
.segment-container {
  flex: 1; /* This ensures each div takes equal space */
  display: flex;
  flex-direction: column;
  align-items: center; /* Align images vertically in the center */
  justify-content: center; /* Align images horizontally in the center */
  overflow: hidden; /* This ensures images don't overflow the div */
  margin: 20px;
  padding: 20px;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.doodle {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
